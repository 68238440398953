<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="1000px"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      :title="isChangeHall? '修改厅房':'选择厅房'"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item
          :label-col="{span:20}"
          :wrapper-col="{span:24}"
          :style="{ display: 'inline-block', width: '100%' }"
        >
          <a-radio-group
            v-decorator="['room_id',{ initialValue: hallId }]"
            @change="handleToInfo"
          >
            <a-list
              :grid="{ gutter: 10, column: 3 }"
              :data-source="listData"
              :loading="loading"
              :pagination="false"
            >
              <a-list-item slot="renderItem" slot-scope="item" style="width: auto;">
                <a-radio :value="item.id" />
                <a-card
                  hoverable
                  style="width: 180px;"
                  @click="() => {
                    form.setFieldsValue({
                      room_id: item.id
                    })
                    handleToInfo({target: {value: item.id}})
                  }"
                >
                  <img
                    class="img"
                    :src="item.imageUrl"
                    alt=""
                    width="100px"
                    height="80px"
                    v-if="item.imageUrl"
                  >
                  <img
                    class="img"
                    src="https://res.anxiaotang.com/assets/6e7f45f0-858f-4a9e-8430-a1ac92ef8cda"
                    alt=""
                    width="100px"
                    height="80px"
                    v-else
                  >
                  <a-card-meta :title="item.name" style="width:100%;text-align:center">
                    <template slot="description">
                      {{ item.status_text }}
                      <span v-if="item.associate" class="custom-blue">（关联套餐）</span>
                    </template>
                  </a-card-meta>
                  <a-card-meta style="width:100%;text-align:center;margin-top: 10px;">
                    <template slot="description">
                      <span>{{ item.unitPrice }}</span>
                    </template>
                  </a-card-meta>
                </a-card>
                <a-button
                  type="primary"
                  style="margin-top: 10px;width: 100%"
                  @click="toShowItems(item.id)"
                >
                  查看详情
                </a-button>
              </a-list-item>
            </a-list>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="停棺时长" :style="{ display: 'inline-block', width: '30%' }" :label-col="{ span: 7 }">
          <a-input-number
            :min="1"
            :max="72"
            :step="1"
            :precision="0"
            style="width: 100%;"
            @blur="handleRoomTime"
            v-decorator="['room_time', {
              initialValue: stopCoffinsTimes === 0 ? 72 : stopCoffinsTimes,
              rules: [
                { required: true, message: '请输入停棺时长' } ]
            }]"
          />
        </a-form-item>
        <a-form-item :label-col="{span:10}" :wrapper-col="{span:19}" :style="{ display: 'inline-block', width: '70%' }">
          <a-radio-group v-decorator="['time_type',{initialValue: initialValue}]" @change="handleTimeType">
            <a-radio value="hour">
              小时
            </a-radio>
            <a-radio value="day">
              天
            </a-radio>
          </a-radio-group>
          <span style="margin-left:30px"> 厅房价格：{{ parseFloat(unitPrice).toFixed(2) }}元/小时</span>
          <span style="margin-left:10px"> 使用费用：{{ parseFloat(hallUsageFee).toFixed(2) }}元</span>
        </a-form-item>
        <a-form-item :label-col="{span:10}" :wrapper-col="{span:19}">
          <a-row>
            <a-col>
              <a-checkbox @change="changeDisinfect" :checked="isDisinfect">
                消毒费（100.00元）
              </a-checkbox>
              <a-radio-group v-if="isDisinfect === true" v-model:value="packageIncludedDisinfection" @change="changePackageIncludedDisinfection">
                <a-radio :value="true">计入账单费用</a-radio>
                <a-radio :value="false">计入厅房费用</a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row>
            <a-checkbox @change="changeBuddhistVenue" :checked="isBuddhistVenue">
              佛事场地费（100.00元）
            </a-checkbox>
            <a-radio-group v-if="isBuddhistVenue === true" v-model:value="packageIncludedBuddhistVenue" @change="changePackageIncludedBuddhistVenue">
              <a-radio :value="true">计入账单费用</a-radio>
              <a-radio :value="false">计入厅房费用</a-radio>
            </a-radio-group>
          </a-row>
          <a-row>
            <a-col>
              <span> 厅房费用：{{ parseFloat(hallFee).toFixed(2) }}元</span>
            </a-col>
          </a-row>
        </a-form-item>

        <pagination
          v-show="pagination.total_count > 0"
          :total-count="pagination.total_count"
          :page.sync="query.page"
          :per-page.sync="query.per_page"
          @change="fetchData"
        />
      </a-form>
    </a-modal>
    <!--选择厅房 -->
    <show-hall
      v-if="isShowHallModal"
      :visible.sync="isShowHallModal"
      :id="itemId"
    />
  </div>
</template>

<script>
import { findHallsById, findHallsByOrder } from '@/api/hall'
import { changeHall, chooseHall } from '@/api/order'
import Pagination from '@/components/Pagination'

export default {
  name: 'ChooseHall',
  components: {
    Pagination,
    ShowHall: () => import('@/views/orders/tenant/operation_modal/HallDetail')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    hallId: {
      type: Number,
      required: true
    },
    stopCoffinsTimes: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    isChangeHall: {
      type: Boolean,
      required: true
    },
    isDisinfectOld: {
      type: Boolean,
      required: true
    },
    isBuddhistVenueOld: {
      type: Boolean,
      required: true
    },
    packageIncludedBuddhist: {
      type: Boolean,
      required: true
    },
    packageIncludedDisinfect: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: this.$form.createForm(this, { name: 'choose_hall' }),
      hall: {},
      roomId: 0,
      itemId: 0,
      hallUsageFee: 0,
      hallFee: 0,
      unitPrice: 0,
      listData: [],
      selectedRowKeys: [],
      submitting: false,
      initialValue: 'hour',
      isShowHallModal: false,
      pagination: {
        total_count: 0
      },
      query: {},
      isDisinfect: true, // 是否消毒
      isBuddhistVenue: true, // 是否使用佛事场地
      packageIncludedDisinfection: true,// 清洁费计入套餐费
      packageIncludedBuddhistVenue: true,// 佛事费计入套餐费
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
    this.$nextTick(() => {
      this.form.setFieldsValue({ room_time: this.stopCoffinsTimes === 0 ? 72 : this.stopCoffinsTimes })
      this.isDisinfect = this.isDisinfectOld
      this.isBuddhistVenue = this.isBuddhistVenueOld
      this.packageIncludedBuddhistVenue = this.packageIncludedBuddhist
      this.packageIncludedDisinfection = this.packageIncludedDisinfect
    })
  },
  methods: {
    handleToInfo(e) {
      this.roomId = e.target.value
      findHallsById(
        { hall_id: this.roomId }
      ).then((res) => {
        this.unitPrice = res.data.unitPrice
        const roomTime = this.form.getFieldValue('room_time')
        const timeType = this.form.getFieldValue('time_type')
        if (roomTime) {
          if (roomTime && timeType === 'day') {
            this.hallUsageFee = this.unitPrice * 24 * roomTime
          } else {
            this.hallUsageFee = this.unitPrice * roomTime
          }
          this.changeHallFee()
        }
      })
    },
    setUnitPrice() {
      findHallsById(
        { hall_id: this.roomId }
      ).then((res) => {
        this.unitPrice = res.data.unitPrice
        if (this.unit && this.unit === 'day') {
          this.hallUsageFee = this.unitPrice * 24 * this.stopCoffinsTimes
        } else {
          this.hallUsageFee = this.unitPrice * this.stopCoffinsTimes
        }
        this.changeHallFee()
      })
    },
    handleRoomTime(e) {
      const timeType = this.form.getFieldValue('time_type')
      if (timeType === 'day') {
        this.hallUsageFee = this.unitPrice * 24 * e.target.value
      } else {
        this.hallUsageFee = this.unitPrice * e.target.value
      }
      this.changeHallFee()
    },
    handleTimeType(e) {
      const timeType = e.target.value
      const roomTime = this.form.getFieldValue('room_time')
      if (roomTime && this.unitPrice) {
        if (timeType === 'day') {
          this.hallUsageFee = this.unitPrice * 24 * roomTime
        } else {
          this.hallUsageFee = this.unitPrice * roomTime
        }
        this.changeHallFee()
      }
    },
    toShowItems(productId) {
      this.isShowHallModal = true
      this.itemId = productId
    },
    fetchData() {
      this.loading = true
      this.listData = []
      this.roomId = this.hallId
      this.$nextTick(() => {
        this.form.setFieldsValue({ room_id: this.hallId, room_time: this.stopCoffinsTimes })
        this.initialValue = this.unit
      })
      if (this.hallId !== 0) {
        this.setUnitPrice()
      }
      findHallsByOrder(
        Object.assign({ order_id: this.id }, this.query)
      ).then((res) => {
        this.data = res.data
        for (let i = 0; i < res.data.length; i++) {
          this.listData.push({
            id: res.data[i].id,
            name: res.data[i].name,
            status_text: res.data[i].status_text,
            unitPrice: res.data[i].unitPrice,
            associate: res.data[i].associate,
            imageUrl: res.data[i].image ? res.data[i].image.url : ''
          })
        }
        this.pagination = res.pagination
        this.loading = false
      })
    },

    changeDisinfect(e) {
      this.isDisinfect = e.target.checked
      this.changeHallFee()
    },

    changeBuddhistVenue(e) {
      this.isBuddhistVenue = e.target.checked
      this.changeHallFee()
    },

    changeHallFee() {
      this.hallFee = this.hallUsageFee
      if (this.isDisinfect && this.packageIncludedDisinfection === false) {
        this.hallFee += 100
      }
      if (this.isBuddhistVenue && this.packageIncludedBuddhistVenue === false) {
        this.hallFee += 100
      }
    },
    changePackageIncludedDisinfection() {
      this.changeHallFee()
    },
    changePackageIncludedBuddhistVenue() {
      this.changeHallFee()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.roomId === 0) {
        this.$warning({
          title: '请选择厅房',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.room_time < 1) {
            this.$warning({
              title: '请输入停棺时长',
              content: ''
            })
            return
          }
          if (values.time_type === 'day' && values.room_time > 3 || values.time_type === 'hour' && values.room_time > 72) {
            this.$warning({
              title: '停棺时长',
              content: '不能超过3天或72小时'
            })
            return
          }
          if (this.isChangeHall && values.roomId === this.roomId) {
            this.$warning({
              title: '请选择与之前不同的厅房',
              content: ''
            })
            return
          }
          this.submitting = true
          if (this.isChangeHall) {
            changeHall(Object.assign({
              order_id: this.id,
              room_id: this.roomId,
              is_disinfect: this.isDisinfect,
              is_buddhist_venue: this.isBuddhistVenue,
              package_included_disinfection: this.packageIncludedDisinfection,
              package_included_buddhist_venue: this.packageIncludedBuddhistVenue,
            }, values)).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          } else {
            chooseHall(Object.assign({
              order_id: this.id,
              room_id: this.roomId,
              is_disinfect: this.isDisinfect,
              is_buddhist_venue: this.isBuddhistVenue,
              package_included_disinfection: this.packageIncludedDisinfection,
              package_included_buddhist_venue: this.packageIncludedBuddhistVenue,
            }, values)).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  margin-bottom: 5px;
  margin-left: 20px;
}

.custom-pagination {
}
</style>

